import { JsonFragment } from 'ethers';
import { Address } from 'viem';

export enum PrerequisitesResponseItemType {
  SMART_CONTRACT_WRITE = 'smart_contract_write',
  KYC = 'kyc',
  CONTACT_EMAIL = 'contact_email',
  CONTACT_PHONE = 'contact_phone'
}

export enum KycPrerequisiteStatus {
  CHECK_IN_PROGRESS = 'check_in_progress',
  KYC_REQUIRED = 'kyc_required',
  KYC_CHECK_FAILED = 'kyc_check_failed'
}

export interface SmartContractWritePrerequisitesResponseItem {
  type: PrerequisitesResponseItemType.SMART_CONTRACT_WRITE;
  params: {
    contractAddress: Address;
    method: string;
    params: Record<string, any>;
    abi: Array<JsonFragment>;
  };
}
export interface KycPrerequisitesResponseItem {
  type: PrerequisitesResponseItemType.KYC;
  params: {
    status: string;
    kycUrl?: string;
  };
}

export interface ContactPhonePrerequisitesResponseItem {
  type: PrerequisitesResponseItemType.CONTACT_PHONE;
  params: {
    status: string;
  };
}

export interface ContactEmailPrerequisitesResponseItem {
  type: PrerequisitesResponseItemType.CONTACT_EMAIL;
  params: {
    status: string;
  };
}

export interface ListSpendingPrerequisitesResponse {
  prerequisites: Array<
    | SmartContractWritePrerequisitesResponseItem
    | KycPrerequisitesResponseItem
    | ContactEmailPrerequisitesResponseItem
    | ContactPhonePrerequisitesResponseItem
  >;
}

export interface ListSpendingPrerequisitesBody {
  cardProgramId: string;
  fundingSourceId: string;
  spendableAmount: string;
  spendableCurrency: 'USD';
  kycType?: 'immersve-conducted';
  kycRedirectUrl?: string;
  kycRegion?: string;
  kycHiddenSteps?: string[];
}
